@use "../settings" as *;

body.page {
    display: flex;
    flex-direction: column;

    nav {
        height: $navbar-height;
        background-color: var(--c-primary-color);
        //background-image: linear-gradient(to bottom, var(--c-blue-medium) 0, var(--c-blue) 100%);
    }

    .navbar-collapse {
        margin-bottom: -3px
    }

    main {
        flex-grow: 1;
    }
}


body.page {
    main {
        padding-top: $navbar-height;
    }
}
