@use "../vendor" as *;
@use "../settings" as *;

.read-more {
    padding-bottom: 6rem!important;
    padding-top: 6rem!important;

    .url-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-flow: column;
        grid-auto-rows: 1fr;
        gap: 2rem;

        @include media-breakpoint-down(lg) {
            gap: 1rem;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
        }

        a {
            font-size: $font-size-xl;
            color: var(--c-primary-color);
            font-weight: 600;
            white-space: nowrap;
        }
    }
}
