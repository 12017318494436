@use "../vendor" as *;

body.invite-users {
    main {

        > * {
            max-width: 1000px;
        }

        .dvTextArea {
            .helptext {
                font-size: $font-size-sm;
                color: $text-muted;
            }
        }

        div.submit {
            padding-top: map-get($spacers, 3);
        }

    }
}
