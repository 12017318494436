@use "../vendor" as *;
@use "../settings";

body.datasource {

    a.page-link {
        // remove bootstrap color to pagination buttons
        --bs-pagination-color: var(--c-secondary-color);
        --bs-pagination-active-border-color: var(--c-secondary-color);
        --bs-pagination-active-bg: var(--c-secondary-color);
    }

    .panel-default {

        border-radius: 0 !important;

        .panel-heading .pull-right {
            .info {
                margin-right: map-get($spacers, 2);
            }

            .excelbtn {
                background: inherit;
                //color: var(--c-secondary-color)
            }

            &.stats {
                margin-top: 5px;
            }
        }
    }

    .filterbox .filtertitle {
        font-size: $h5-font-size;
    }

    table {
        margin-bottom: 0 !important;
    }

    .search-form .clear-button {
        display: none
    }

    .filterbx {
        min-width: 100px;
        width: 225px !important;

        .panel-heading {
            padding: 19px;
        }

        footer.panel-footer {
            display: none;
        }

        .panel-body .DataFilter > .content {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .CheckboxSelectWidget {
                input {
                    margin-top: 5px;
                    margin-right: 10px;
                }
            }
        }

        @include media-breakpoint-down(xl) {
            width: 100% !important;

            .panel-body {
                display: flex;
                flex-wrap: wrap;

                .DataFilter > .content {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 25px;
                }
            }
        }

    }

    .rowbx {
        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }
    }
}

