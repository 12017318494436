body.registration {
    height: unset !important;
    padding: 5px!important;

    main {
        div[field='consent'] {
            display: flex;
            flex-direction: row-reverse;

            label.hvCheckboxInput {
                font-weight: 400 !important;
            }
        }
    }
}
