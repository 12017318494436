
form {
    --dk-form-gap: var(--g-5);

    .errordescr {
        color: var(--bs-red);
    }

    .form-control {
        border-radius: 0 !important;
    }

    label {
        font-weight: 600 !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    // label {
    //     text-transform: none !important;
    //     width: 100% !important;
    // }

    .form-check {
        padding-left: 0 !important;

        .form-check-input {
            border: 2px solid #ced4da !important;
            border-radius: 0 !important;
        }
    }

    input[readonly] {
        background-color: var(--c-background);
        opacity: 0.7;
        cursor: not-allowed;
    }

    input[readonly]:focus {
        background-color: var(--c-background);
    }

    .dvBooleanInput {
        margin-top: 9px;
    }

    select.form-control, input.form-control {
        padding-left: 0px;
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .form-control {

        &.validated-false {
            border-color: var(--c-danger);
            //@include depth.depth-1();
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);
        }

        &.validated-true {
            border-color: var(--c-success);
            //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
        }
    }

    input[type="checkbox"] {
        accent-color: var(--c-success);
    }
}
