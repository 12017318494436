@use "../vendor" as *;
@use "../settings" as *;


.btn {
    // remove button border radius
    --bs-btn-border-radius: 0;
}

.btn-disabled {
    border-color: $gray-200;
    cursor: not-allowed !important;
}

.btn-light {
    border-color: $gray-400 !important;
}

$mynewcolor: #2F95B1;       // FIXME: wrong name, wrong placement

.btn-primary {
    color: white;
    @include button-variant(
        $mynewcolor, 
        darken($mynewcolor, 7.5%), 
        $hover-background: darken($mynewcolor, 10%), 
        $hover-border: lighten($mynewcolor, 5%), 
        $active-background: lighten($mynewcolor, 10%), 
        $active-border: darken($mynewcolor, 30%)
    );
}

.btn-outline-primary {
    @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor, 5%), $mynewcolor);
}


$mynewcolor: #508EA1;       // FIXME: wrong name, wrong placement

.btn-secondary {
    color: white;
    @include button-variant(
        $mynewcolor, 
        darken($mynewcolor, 7.5%), 
        $hover-background: darken($mynewcolor, 10%), 
        $hover-border: lighten($mynewcolor, 5%), 
        $active-background: lighten($mynewcolor, 10%), 
        $active-border: darken($mynewcolor, 30%)
    );
}

.btn-outline-secondary {
    @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor, 5%), $mynewcolor);
}
