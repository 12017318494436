body.admin-info {

    section {
        padding-block: 6rem;
    }

    #admin-intro {
        .img-wrapper {
            max-width: 800px;
            margin: auto;

            img {
                // FIXME: this looks like a component definition..?
                border: 5px solid var(--c-primary-color);
                box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
            }
        }

    }

}
