@use "../vendor" as *;
@use "../settings";


body.coachingreport {

    --data-color-1: var(--c-primary-color) !important;

    .stat-body {
        .dkcharts-gauge .highcharts-title {
            color: var(--c-primary-color) !important;
        }


        @include media-breakpoint-down(xl) {

            .highcharts-title {
                font-size: 100px !important;
                top: 125px !important;

                @include media-breakpoint-down(xl) {
                    font-size: 80px !important;
                    top: 60px !important;
                }

                @include media-breakpoint-down(md) {
                    font-size: 60px !important;
                    top: 50px !important;
                }
            }
        }

        .highcharts-title::after {
            content: ' %'
        }


        .stat-subheader {
            color: var(--c-primary-color);
        }

        .score-box {
            border: 5px solid var(--c-primary-color);
            padding: 25px 100px;
            font-size: 2rem;
            font-weight: 300;

            @include media-breakpoint-down(md) {
                padding: 15px 80px;
                font-size: 1.5rem;
            }

            .actual-score {
                font-size: 4rem;
                font-weight: 400;
                color: var(--c-primary-color);

                @include media-breakpoint-down(md) {
                    font-size: 2rem;
                }

            }
        }

    }

    .topicscore {
        .table {
            font-size: 1.25rem;

            thead {
                background-color: var(--c-primary-color);
            }
        }
    }

}
