footer {
    width: 100%;
    background-color: var(--c-primary-color);
    //background-image: linear-gradient(to bottom, var(--c-blue-medium) 0, var(--c-blue) 100%);

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        color: var(--c-neutral-10);
    }
}
