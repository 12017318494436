@use "../settings" as *;

body.receipt {
        nav.navbar {
        background-color: var(--c-primary-color);
    }

    main {
        padding-top: $navbar-height;
    }
}
