@use "../settings" as *;
@use "../vendor" as *;

//body.statistics main {
//    padding: 0 !important;
//}

// this is component (see fixme below)
.stats.card {

    padding: var(--stats-card-space);
    max-width: 890px;
    overflow: auto;

    .stat-header {
        // margin/padding gymnastics to keep the text in the same place
        // and let the border extend to the edge of the card.
        // margin-inline: calc(-1 * var(--stats-card-space));
        // padding-inline: var(--stats-card-space);
        // border-bottom: 1px solid $c-neutral-50;
        @include font-size($h3-font-size);
        line-height: 1;
    }

    .stat-subheader {
        @include font-size($h5-font-size);
        margin-bottom: 0;

        & + small {
            color: $c-neutral-45;
            line-height: 1;
            margin-bottom: 1em;
        }
    }

    .stat-body {
        .dkcharts {
            min-width: 165px;
            min-height: 165px;
            margin: calc(var(--stats-card-space) / 2);
            margin-bottom: 0;
        }
    }

}

// multi-statistics layout (this is layout)
//
// We have lines of boxes inside main.
// We want consistent spacing around the boxes.
.statistics-wrapper {
    --stats-card-space: 2rem;

    // set the outside space 
    // XXX: top space is still fubar due to nav being position:absolute (fix..)
    margin: var(--stats-card-space);

    // set inter-box space (in the y-direction) 
    gap: var(--stats-card-space);

    // direct (flex-)chilren must set space (in the x-direction)
    & > .d-flex {
        gap: 2rem;
    }
}

//FIXME: statistics boxen looks more like components to me...?
//body.dashboard {
// Something is trying really hard to give main a padding...
// XXX: figure out where the padding is coming from
//main { padding: 0 !important; }


// XXX: move to a dkcharts file in components/..?

// we want transparent backgrounds on gauges..
.highcharts-background {
    opacity: 0;
}
