@use "../vendor" as *;
@use "../settings";

// FIXME: this is a page, not a layout...

body.homepage {
    
    header > img.logo {
        --logo-width: 400px;
        width: var(--logo-width);
        min-width: 250px;
        position: relative;
        // align the logo text with the body text
        // left: calc(var(--logo-width) / -2);

        // align the center of the logo icon with the body text
        left: calc(var(--logo-width) * -0.228);

        @include media-breakpoint-down(md) {
            --logo-width: 301px;
            left: 0;
            margin-block: 2rem;
        }
    }

    a:hover {
        color: var(--c-neutral-10);
    }

    main {

        @include media-breakpoint-down(md) {
            .lead {
                font-size: 1rem !important;
            }
        }

        .showcase {
            background-image: url("image/background-42x9_ss.jpg");
            background-size: cover;
            background-position: center;

            @include media-breakpoint-down(lg) {
                background-image: url("image/AdobeStock_171333654.jpg");
            }

            @include media-breakpoint-down(md) {
                background-image: url("image/background-9x16.jpg");
                background-position: bottom;
                .lead {
                    font-size: 1rem;
                }
            }

            @include media-breakpoint-down(sm) {
                background-position: bottom;
            }

            .showcase-text {
                > p {
                    width: 55%;
                }

                @include media-breakpoint-down(sm) {
                    > p {
                        width: 100%;
                    }
                }

            }
        }

        .questions {
            .accordion-header button {
                font-size: 1.5rem;
                @include media-breakpoint-down(md) {
                    font-size: 1.2rem;
                }
            }
        }

        .product {
            .product-steps {
                grid-template-columns: 1fr 1fr 1fr;
                grid-auto-flow: column;
                grid-auto-rows: 1fr;
                gap: 2rem;

                @include media-breakpoint-down(lg) {
                    gap: 1rem;
                }

                @include media-breakpoint-down(md) {
                    grid-template-columns: 1fr;
                    grid-auto-flow: row;
                }

                .product-step {
                    //border: 3px solid var(--c-blue-medium) !important;
                    padding: 2rem !important;

                    @include media-breakpoint-down(lg) {
                        padding: 1rem !important;
                    }

                    .card-body {

                        dk-icon {
                            color: var(--c-primary-color);
                        }

                        .card-title {
                            @include media-breakpoint-up(md) {
                                min-height: 70px;
                            }
                        }
                    }

                    .step-label {
                        position: absolute;
                        left: -21px;
                        top: -20px;
                        font-size: 1.5rem;
                        padding: 2px 14px;
                        color: white;
                        border-radius: 20px;
                        background-color: var(--c-secondary-color);
                    }
                }
            }
        }
    }
}
