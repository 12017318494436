@use "../vendor" as *;


body[name="netseasy-payment"] {

    .modal-box {

        @include media-breakpoint-up(sm) {
            max-width: 540px !important;
        }

        @include media-breakpoint-up(md) {
            max-width: 720px !important;
        }

        @include media-breakpoint-up(lg) {
            max-width: 960px !important;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1140px !important;
        }
    }

    div.dibs-checkout-wrapper {

        iframe {
            min-height: unset !important;
        }
    }
}
