@use "../vendor" as *;

body.profile-page {
    .profile-person {
        .person {
            margin: map-get($spacers, 3) 0;

            .full-name {
                display: flex;
                justify-content: space-between;

                h3 {
                    font-size: $h2-font-size;
                    padding: 0.75rem 0.375rem;
                }
            }

            .edit-options {
                gap: 2px;

                //> a {
                //    border-color: $gray-200;
                //}
            }

            .field-data {
                a {
                    text-decoration: none;
                }
            }
        }
    }

    .action-box {
        .action-button {
            width: 240px;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .superuser-admin {
        margin-top: unset !important;
    }

    .profile-actions {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .result-area {
        #results td[axis=passing_grade] {
            text-align: center;
        }

        #results td[axis=attempt_number],
        #results td[axis=percentage_score],
        #results td[axis=score] {
            text-align: right
        }
    }

    .modal {
        .modal-buttons {
            gap: 10px;

            @include media-breakpoint-down(sm) {
                gap: 5px;
            }

            > button {
                min-width: 60px;
            }
        }
    }
}
