@use "../vendor" as *;
@use "../settings" as *;

html, body {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

body.dashboard {
    display: flex;
    flex-direction: column;

    nav.navbar {
        height: $navbar-height;
        background-color: var(--c-primary-color);

        .navbar-brand {
            @include media-breakpoint-down(md) {
                width: unset!important;
            }
        }

    }

    .center-box {

        padding-top: $navbar-height;

        // FIXME: this should probably be in components
        .navbar-left {

            .nav {
                flex-grow: 1;

                .nav-item {

                    margin: 5px 0;

                    .nav-link {
                        color: $c-neutral-0;
                    }

                    .nav-link:hover {
                        // XXX: after moving to components/navbar, make separate $c-navbar-link(-hover,active) variables..?
                        background-color: $c-neutral-80;
                    }

                    .nav-link.active {
                        background-color: $c-neutral-0;
                    }

                }
            }

        }

        main {
            padding: 2rem;
            height: calc(100vh - $navbar-height);
            overflow-y: scroll;

            .dash-wrapper {
                padding: 2rem;
            }
        }

    }
}

.center-box {
    flex-grow: 1;
    display: flex;
    color: var(--c-db);
}
