@use "../vendor" as *;


body.sales-terms {
    .terms {

        section {
            > h3 {
                border-bottom: 2px solid var(--c-background);
                padding: 20px 0 10px 0;
            }
        }

        .payment-logos {
            gap: 2%;
            height: 20px;

            @include media-breakpoint-down(sm) {
                height: 30px;
            }
        }
    }

}
