body.faq {
    .faq-category {
        color: black;
    }

    .nav-tabs-custom .nav-item .nav-link.active {
        color: var(--c-primary-color);
    }

    .nav-tabs-custom .nav-item .nav-link {
        border: none;
    }

    .nav-tabs-custom .nav-item {
        position: relative;
        color: var(--c-primary-color);
    }

    .nav-tabs-custom .nav-item .nav-link.active:after {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .nav-tabs-custom .nav-item .nav-link::after {
        content: "";
        background: var(--c-primary-color);
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        -webkit-transition: all 250ms ease 0s;
        transition: all 250ms ease 0s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
