
@media print {
    // this was just an annoyance during print-debugging..
    #dkdjango-template-debug {display: none;}

    // Set up the page dimensions (not entirely sure about the 1in margin, but it
    // is not too far off NS 4129:1995.
    @page {
        size: A4;
        margin: 1in;
    }

    // remove container margins on top elements (@page has proper margins, above)
    :is(main, footer, body > nav.navbar) > .container {
        margin-inline: 0 !important;
    }

    body>footer {
        // force black text
        color: black !important;
        a, 
        .text-white, 
        &.text-white, 
        dk-icon {color: black !important;}

        // left align everything
        .justify-content-center {
            justify-content: start !important;
        }

        // don't display the white logo...
        .u-logo { display: none; }

        // ...instead add the regular colorful logo as a background image on the parent
        .u-url {
            display: block;
            width: 153px;
            height: 33px;
            background-image: url("https://static.datakortet.no/logo/gjeldende/norsktest-logo.png");
            background-size: cover;
            background-repeat: no-repeat;

            // a little work to force browsers to output background images when printing 
            // to paper (chrome still doesn't want to though...).
            -webkit-print-color-adjust: exact !important;
            color-adjust: exact !important;
            print-color-adjust: exact !important;
        }

        // hide links
        .terms-and-conditions,
        .gdpr,
        .gmap-link {display: none;}
    }
}