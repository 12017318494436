@use "sass:map";
@use "../settings";
@use "../vendor" as *;

body.get-access {
    main {
        section {
            height: 100%;

            .product {
                max-width: 500px;

                .dkform {
                    button[type="submit"] {
                        width: 80px;
                    }

                    .vPositiveIntegerInput {
                        max-width: 80px;
                        margin: 0.5rem auto;
                    }
                }

                ul.check-list {
                    //font-size: settings.$font-size-xl;
                    width: clamp(25ch, 50%, 90ch);
                    margin-inline: auto;

                    li {
                        padding-left: .5ex;
                        text-align: left;
                        margin-left: 20px;
                    }

                    li::marker {
                        @include settings.font-awesome("\f00c");
                        color: green;
                        font-size: $font-size-lg;
                    }
                }
            }

            .payment-logos {
                gap: 2%;
                height: 30px;

                @include media-breakpoint-down(sm) {
                    height: 20px;
                }
            }
        }
    }
}
