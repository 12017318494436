@use "variables" as *;
@use "../vendor" as *;

// @import url("https://fonts.googleapis.com/css?family=Open+Sans:700,400");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');


$font-size-xl: $font-size-base * 1.5;
$font-size-xxl: $font-size-base * 2;

:is(h1, h2, h3) {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: var(--c-brand-color);
}

// h1, .h1-size { font-size: var(--fs-1); }
// h2, .h2-size { font-size: var(--fs-2); }
// h3, .h3-size { font-size: var(--fs-3); }
// h4, .h4-size { font-size: var(--fs-4); }

@mixin font-awesome($glyph) {
    content: "#{$glyph}";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

$navbar-height: 72px;
