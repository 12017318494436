/*
* Info Container
* =============
* The info container is used to display large blocks of text, which needs
* to have lines of text that are not too long on big screens.
*
* Add extra padding to the container on big screens, and float the logo 
* to the right.
*/

@use "../vendor" as *;


.info.container {
    .logo {
        display: block;
        margin: 0 0 2rem 0;
        img {
            max-width: 100%;
            height: 4rem;
            aspect-ratio: 320/64;
        }
    }
}


@include media-breakpoint-up(xl) {
    .info.container {
        padding-inline: 15rem;

        .logo {
            float:right;
            margin: 0 0 1rem 1rem;
        }
    }
}
