@use "../vendor" as *;


body.product-info {
    main {
        counter-reset: productsteps;

        section {
            padding-top: 3rem;
            padding-bottom: 3rem;

            @include media-breakpoint-down(md) {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }

    }

    h2.step-title {
        --counter-size: 50px;
        --counter-size-adjust: 4px;
        color: #42636e;
    }

    h2.step-title::before {
        content: counter(productsteps);
        counter-increment: productsteps;
        margin-right: .5ex;
        color: white;
        background-color: #42636e;
        border-radius: 50%;
        text-align: center;
        font-size: calc(var(--counter-size) - 2 * var(--counter-size-adjust));
        line-height: calc(var(--counter-size) - var(--counter-size-adjust));
        width: var(--counter-size);
        height: var(--counter-size);
        display: inline-block;
    }

    .step-icon {
        font-size: 128px;
        color: #42636e;
        margin: 2rem;
    }

    .time-estimate {
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    .time-estimate dk-icon {
        font-size: xx-large;
        color: #666;
    }

    .step {
        /* outline: 1px dotted blue; */
        display: grid;
        /* gap: 4rem; */
        margin-bottom: 3rem;
        margin-top: 3rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
        }

        grid-template-columns: 480px minmax(60ch, 1fr);

        @include media-breakpoint-down(xl) {
            grid-template-columns: 400px minmax(60ch, 1fr);
        }

        @include media-breakpoint-down(lg) {
            grid-template-rows: auto;
            grid-template-columns: unset;
            gap: 2rem;
        }

        //@include media-breakpoint-up(lg) {
        //    max-width: 960px !important;
        //}

        /* grid-template-rows: 1fr 1fr 1fr; */
        /* grid-auto-rows: auto; */
        justify-items: center;
        align-items: end;
    }

    section p.ingress {
        font-weight: 600;
        max-width: 60ch;
        font-size: 1.2rem;
    }

    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
    }

    .product-info {
        display: flex;
        flex-direction: column;
        //gap: 4rem;

    }

}
