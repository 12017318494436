@use "sass:math";
@use "../settings" as *;
@use "depth" as *;
@use "../vendor" as *;


.modal-page {
    height: unset!important;
    width: unset!important;
    background-color: var(--c-background);
    display: block;

    form {
        label {
            font-weight: 600;
        }

        input {
            border-radius: 0;
            border-left: none;
            border-right: none;
            border-top: none;
            padding-left: 0;
        }

        input:focus {
            box-shadow: none;
        }
    }

    //&.ok {
    //    background-color: var(--c-ok);
    //}
    //
    //&.danger {
    //    background-color: var(--c-danger);
    //}
    //
    //&.error {
    //    background-color: var(--c-error);
    //}
    //
    //&.warning {
    //    background-color: var(--c-warning);
    //}
    //
    //&.info {
    //    background-color: var(--c-info);
    //}

    .modal-box {
        > h1 {margin-bottom: map-get($spacers, 4);}
        $transition-duration: .2s;
        transition: margin $transition-duration, padding $transition-duration;

        padding: $spacer * 4;

        margin-block: 5%;
        max-width: 700px;
        min-height: 400px;


        position: relative;
        display: flex;
        flex-direction: column;
        align-content: space-between;

        @extend .depth-2;
        background-color: var(--c-basecolor);

        @media #{$tablet} {
            $margin: 10px;
            max-width: calc(100% - 2 * #{$margin});
            margin-block: $margin;
            padding: $spacer * 2;
        }

        > header {
            border-bottom: 1px solid var(-- $c-neutral-20);
            padding-bottom: var(--g-3);
            margin-bottom: var(--g-3);

            .logo {
                max-width: 400px;

                @media #{$mobile} {
                    max-width: 100%;
                }
            }
        }

        > footer {
            hr {
                width: 30%;
                text-align: left;
            }
        }
    }
}

