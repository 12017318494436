@use "../vendor" as *;
@use "../settings";

.custom-progress {
    --progress-pct: 50%;
    --progress-height: 1.1rem;
    --progress-color: var(--c-primary-color);
    --progress-background: #eaeaea;
    position: relative;
    display: flex;
    height: var(--progress-height);
    border: 1px solid var(--progress-color);
    border-radius: 8px;
    font-size: calc(0.8 * var(--progress-height));
    overflow: hidden;

    .back {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: var(--progress-color);
        color: white;
        margin-top: -2px;
    }

    .front {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
        color: var(--progress-color);
        clip-path: inset(0 0 0 var(--progress-pct));
        -webkit-clip-path: inset(0 0 0 var(--progress-pct));
        transition: clip-path 1s linear;
    }
}
