.dkauth {
    h2 {
        margin-bottom: 3rem !important;
    }

    .forgotten-password {
        --bs-text-opacity: 1;
        color: #6c757d !important;
        text-decoration: none !important;
    }
}

.dkauth.password-reset {
    div.submit {
        padding-top: 0.5rem;
    }
}
