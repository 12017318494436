@use "../settings" as *;

:root {
    --g-1: #{$g-1};
    --g-2: #{$g-2};
    --g-3: #{$g-3};
    --g-4: #{$g-4};
    --g-5: #{$g-5};


    --c-background: #{$c-background};
    --c-neutral: #{$c-neutral};

    --c-db: #{$c-db};
    --c-ok: #{$c-ok};
    --c-success: #{$c-success};
    --c-info: #{$c-info};
    --c-warning: #{$c-warning};
    --c-danger: #{$c-danger};
    --c-blue: #{$c-blue};
    --c-blue-medium: #{$c-blue-medium};
    --c-blue-comp: #{$c-blue-comp};
    --c-blue-dark: #{$c-blue-dark};
    --c-marked-color: #{$c-marked-color};
    --c-highlight-bg-color: #{$c-highlight-bg-color};


    --c-basecolor: #{$c-basecolor};
    --c-primary-color: var(--c-blue-dark);
    --c-secondary-color: var(--c-blue-medium);
    --c-brand-color: #{$c-brand-color};
    --c-logo: #{$c-logo};

    --c-neutral-0: #{$c-neutral-0};
    --c-neutral-5: #{$c-neutral-5};
    --c-neutral-10: #{$c-neutral-10};
    --c-neutral-15: #{$c-neutral-15};
    --c-neutral-20: #{$c-neutral-20};
    --c-neutral-25: #{$c-neutral-25};
    --c-neutral-30: #{$c-neutral-30};
    --c-neutral-35: #{$c-neutral-35};
    --c-neutral-40: #{$c-neutral-40};
    --c-neutral-45: #{$c-neutral-45};
    --c-neutral-50: #{$c-neutral-50};
    --c-neutral-55: #{$c-neutral-55};
    --c-neutral-60: #{$c-neutral-60};
    --c-neutral-65: #{$c-neutral-65};
    --c-neutral-70: #{$c-neutral-70};
    --c-neutral-75: #{$c-neutral-75};
    --c-neutral-80: #{$c-neutral-80};
    --c-neutral-85: #{$c-neutral-85};
    --c-neutral-90: #{$c-neutral-90};
    --c-neutral-95: #{$c-neutral-95};
    --c-neutral-100: #{$c-neutral-100};


}

// dkcharts/highcharts
.dkcharts {
    --data-color-1: var(--c-primary-color);
}
